import React, { useState, useEffect, useRef } from 'react';
import {
  Search,
  MoreVertical,
  Edit,
  Send,
  Loader,
  AlertCircle,
  X,
} from 'lucide-react';

const AdvancedMessageInterface = () => {
  const [activeChat, setActiveChat] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentTab, setCurrentTab] = useState('All');
  const [isLoading, setIsLoading] = useState(false);
  const [sendError, setSendError] = useState(null);
  const [isMarkingRead, setIsMarkingRead] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);

  const API_BASE = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000/api' 
  : `https://${window.location.hostname}/api`;


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchConversations = async () => {
    console.log('FETCH CONVERSATIONS - STARTED');
    console.log('Current API_BASE:', API_BASE);
    setIsLoading(true);
    
    try {
      const response = await fetch(`${API_BASE}/conversations`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      console.log('Response status:', response.status);
      const data = await response.json();
      console.log('Received data:', data);
      
      if (!data.conversations) {
        console.error('No conversations array in response:', data);
        return;
      }
      
      console.log(`Found ${data.conversations.length} conversations:`, data.conversations);
      setConversations(data.conversations);
      
      // If we have conversations and no active chat, set the first one
      if (data.conversations.length > 0 && !activeChat) {
        console.log('Setting active chat to:', data.conversations[0].phone_number);
        setActiveChat(data.conversations[0].phone_number);
      }
    } catch (error) {
      console.error('FETCH CONVERSATIONS - ERROR:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessages = async (phoneNumber) => {
    if (!phoneNumber) return;
    
    console.log('FETCH MESSAGES - STARTED for phone:', phoneNumber);
    const url = `${API_BASE}/messages/${phoneNumber}`;
    console.log('Fetching from URL:', url);
    setIsLoading(true);
    
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      console.log('Response status:', response.status);
      const text = await response.text(); // Get response as text first
      console.log('Raw response:', text);
      
      const data = JSON.parse(text); // Then parse it
      console.log('Parsed data:', data);
      
      if (data.messages) {
        setMessages(data.messages);
      }
    } catch (error) {
      console.error('FETCH MESSAGES - ERROR:', error);
      console.error('Full error details:', error.message);
    } finally {
      setIsLoading(false);
    }
};

  const markConversationAsRead = async (phoneNumber) => {
    if (isMarkingRead) return;
    
    setIsMarkingRead(true);
    try {
      const response = await fetch(`${API_BASE}/mark-read/${phoneNumber}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      setConversations(conversations.map(conv => 
        conv.phone_number === phoneNumber 
          ? { ...conv, unread_count: 0 }
          : conv
      ));
    } catch (error) {
      console.error('Failed to mark conversation as read:', error);
    } finally {
      setIsMarkingRead(false);
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !activeChat) return;
    setSendError(null);

    try {
      const response = await fetch(`${API_BASE}/messages/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone_number: activeChat,
          content: newMessage.trim()
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }
      
      const data = await response.json();
      console.log('Message sent:', data);
      
      setNewMessage('');
      
      // Optimistic update
      setMessages(prev => [...prev, {
        id: Date.now(),
        content: newMessage.trim(),
        direction: 'outbound',
        created_at: new Date().toISOString(),
        status: 'pending'
      }]);
      
      // Refresh data
      await Promise.all([
        fetchConversations(),
        fetchMessages(activeChat)
      ]);
      
      messageInputRef.current?.focus();
    } catch (error) {
      console.error('Failed to send message:', error);
      setSendError('Failed to send message. Please try again.');
    }
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  useEffect(() => {
    if (activeChat) {
      fetchMessages(activeChat);
      messageInputRef.current?.focus();
    }
  }, [activeChat]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  const formatMessageTime = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();

    if (isToday) {
      return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    }
    
    return date.toLocaleDateString([], { 
      month: 'short', 
      day: 'numeric'
    });
  };

  const filterConversations = (conversations, searchTerm) => {
    if (!searchTerm.trim()) {
      return conversations.filter((conv) => {
        if (currentTab === 'Unread') {
          return conv.unread_count > 0;
        }
        return true;
      });
    }

    const searchRegex = new RegExp(searchTerm, 'i');
    return conversations.filter((conv) => {
      const matchesTab = currentTab === 'All' || 
        (currentTab === 'Unread' && conv.unread_count > 0);
      
      const matchesName = searchRegex.test(`${conv.first_name} ${conv.last_name}`);
      const matchesMessage = searchRegex.test(conv.last_message_preview);
      const matchesPhone = searchRegex.test(conv.phone_number);

      return matchesTab && (matchesName || matchesMessage || matchesPhone);
    });
  };

  const highlightMatch = (text, searchTerm) => {
    if (!searchTerm.trim()) return text;

    try {
      const regex = new RegExp(`(${searchTerm})`, 'gi');
      return text.split(regex).map((part, i) => 
        regex.test(part) ? (
          <span key={i} className="bg-yellow-200 dark:bg-yellow-900">{part}</span>
        ) : part
      );
    } catch (e) {
      return text;
    }
  };

  const filteredConversations = filterConversations(conversations, searchTerm)
    .sort((a, b) => new Date(b.last_message_timestamp) - new Date(a.last_message_timestamp));


  return (
    <div className="w-full h-[700px] max-w-5xl">
      <div className="flex h-full rounded-xl bg-white dark:bg-gray-800 
        shadow-xl 
        dark:shadow-xl
        overflow-hidden"
      >
        {/* Sidebar */}
        <div className="w-80 border-r border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900 flex flex-col overflow-hidden">
          {/* Search Header */}
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Messages</h2>
              <button className="p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full">
                <Edit size={20} className="text-blue-500" />
              </button>
            </div>
            <div className="relative">
              <Search
                size={16}
                className="absolute left-3 top-3 text-gray-400"
              />
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search messages or contacts"
                className="w-full rounded-full bg-gray-200 dark:bg-gray-700 pl-10 pr-4 py-2 
                  text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400
                  focus:outline-none focus:ring-1 focus:ring-gray-300 dark:focus:ring-gray-600"
              />
              {searchTerm && (
                <button
                  onClick={() => setSearchTerm('')}
                  className="absolute right-3 top-2 p-1 hover:bg-gray-300 dark:hover:bg-gray-600 
                    rounded-full text-gray-500 dark:text-gray-400"
                >
                  <X size={14} />
                </button>
              )}
            </div>
          </div>

          {/* Tabs */}
          <div className="flex border-b border-gray-200 dark:border-gray-700">
            <button
              onClick={() => setCurrentTab('All')}
              className={`flex-1 p-2 text-center transition-colors ${
                currentTab === 'All'
                  ? 'border-b-2 border-blue-500 text-blue-500'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
              }`}
            >
              All
            </button>
            <button
              onClick={() => setCurrentTab('Unread')}
              className={`flex-1 p-2 text-center transition-colors ${
                currentTab === 'Unread'
                  ? 'border-b-2 border-blue-500 text-blue-500'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
              }`}
            >
              Unread
            </button>
          </div>

          {/* Conversations List */}
          <div className="overflow-y-auto overflow-x-hidden flex-1">
            {filteredConversations.map((conv) => (
              <div
                key={conv.phone_number}
                onClick={() => {
                  setActiveChat(conv.phone_number);
                  markConversationAsRead(conv.phone_number);
                }}
                className={`flex items-center p-4 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800
                  ${activeChat === conv.phone_number ? 'bg-gray-100 dark:bg-gray-800' : ''}
                  ${isMarkingRead && conv.phone_number === activeChat ? 'opacity-75' : ''}`}
              >
                <div className="relative">
                  <div className="w-12 h-12 rounded-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center">
                    <span className="text-lg font-semibold text-gray-600 dark:text-gray-200">
                      {conv.first_name[0]}
                    </span>
                  </div>
                </div>
                <div className="ml-4 flex-1 min-w-0">
                  <div className="flex justify-between items-center">
                    <h3 className="font-semibold truncate pr-2 text-gray-900 dark:text-white">
                      {searchTerm ? 
                        highlightMatch(`${conv.first_name} ${conv.last_name}`, searchTerm) :
                        `${conv.first_name} ${conv.last_name}`
                      }
                    </h3>
                    <span className="text-xs text-gray-500 dark:text-gray-400 flex-shrink-0">
                      {formatMessageTime(conv.last_message_timestamp)}
                    </span>
                  </div>
                  <div className="flex justify-between items-start">
                    <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2 max-w-[180px]">
                      {searchTerm ? 
                        highlightMatch(conv.last_message_preview, searchTerm) :
                        conv.last_message_preview
                      }
                    </p>
                    {conv.unread_count > 0 && (
                      <span className="ml-2 bg-blue-500 text-white rounded-full px-2 py-0.5 text-xs flex-shrink-0">
                        {conv.unread_count}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Chat Area */}
        <div className="flex-1 flex flex-col bg-gray-50 dark:bg-gray-900">
          {activeChat ? (
            <>
              {/* Chat Header */}
              <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
                <div className="flex items-center">
                  <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {conversations.find(c => c.phone_number === activeChat)?.first_name} {conversations.find(c => c.phone_number === activeChat)?.last_name}
                  </h2>
                  <span className="text-sm text-gray-500 dark:text-gray-400 ml-2">
                    {activeChat}
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <button className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full">
                    <MoreVertical size={20} className="text-gray-500 dark:text-gray-400" />
                  </button>
                </div>
              </div>

              {/* Messages */}
              <div className="flex-1 overflow-y-auto p-4 space-y-4">
                {isLoading ? (
                  <div className="flex-1 flex items-center justify-center">
                    <Loader className="w-8 h-8 text-blue-500 animate-spin" />
                  </div>
                ) : (
                  <>
                    {messages.map((message) => (
                      <div
                        key={message.id}
                        className={`flex ${
                          message.direction === 'outbound'
                            ? 'justify-end'
                            : 'justify-start'
                        }`}
                      >
                        <div
                          className={`max-w-[70%] rounded-2xl px-4 py-2 ${
                            message.direction === 'outbound'
                              ? 'bg-blue-500 text-white'
                              : 'bg-white dark:bg-gray-800 text-gray-900 dark:text-white'
                          }`}
                        >
                          <p className="break-words">{message.content}</p>
                          <p
                            className={`text-xs mt-1 ${
                              message.direction === 'outbound'
                                ? 'text-blue-100'
                                : 'text-gray-500 dark:text-gray-400'
                            }`}
                          >
                            {formatMessageTime(message.created_at)}
                          </p>
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </>
                )}
              </div>

              {/* Message Input */}
              <form onSubmit={handleSend} className="p-4 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
                {sendError && (
                  <div className="flex items-center text-red-500 text-sm mb-2">
                    <AlertCircle size={16} className="mr-1" />
                    {sendError}
                  </div>
                )}
                <div className="flex items-center space-x-2">
                  <input
                    ref={messageInputRef}
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message"
                    className="flex-1 rounded-full border border-gray-300 dark:border-gray-600 
                      px-4 py-2 bg-white dark:bg-gray-700
                      text-gray-900 dark:text-white
                      placeholder-gray-500 dark:placeholder-gray-400
                      focus:outline-none focus:border-gray-400 dark:focus:border-gray-500 
                      focus:ring-1 focus:ring-gray-200 dark:focus:ring-gray-600"
                  />
                  <button
                    type="submit"
                    disabled={!newMessage.trim()}
                    className="rounded-full bg-blue-500 p-2 text-white 
                      hover:bg-blue-600 dark:hover:bg-blue-400
                      focus:outline-none focus:ring-2 
                      focus:ring-blue-500/20 dark:focus:ring-blue-500/40 
                      disabled:opacity-50 disabled:cursor-not-allowed 
                      transition-colors"
                  >
                    <Send size={20} />
                  </button>
                </div>
              </form>
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center text-gray-500 dark:text-gray-400">
              Select a conversation to start messaging
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvancedMessageInterface;